.ant-tree{
    font-size: 16px !important;
}

.left-tree-context {
    overflow-y:auto;
}
.ant-tree .ant-tree-node-content-wrapper {
    line-height: 30px !important;  
}

.ant-divider-horizontal {
    margin: 12px 0 !important;
}


.Organization {
    height: 97%;
    display: flex;
    margin: 10px 0 15px 0;
}

.Organization .left-tree{
    width: 320px;
    background: #fff;
    min-height: 100%;
}

.Organization .left-tree .search{
    width: 300px;
    margin:15px 5px 5px 10px;
}

.Organization .left-tree .button{
    width: 300px;
    margin:0px 10px 5px 10px;
}

.Organization .right-con{
    width: 100%;
    background: #fff;
    margin: 0px 0 15px 15px;
    min-height: 100%;
}

.Organization .right-con .root-org{
    margin-top: 30px;
}

.colStyle {
    display: 'flex';
    justify-content:'center';
    align-items:'center';
}



