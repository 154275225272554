.list {
  width: 100vw - 12px;
  background: #fff;
  height: 92%;
  overflow-y: scroll;
  margin-top: 10px;
  margin-bottom: 10px;

  &::-webkit-scrollbar {
    width: 10px;
    height: 100%;
    background: #fff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #ededed;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #535353;
  }
}

.nav {
  text-align: left;
  font-size: 16px;
  background-color: #fff;
  border: 1px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  width: 800px;
  height: 600px;

  tr {
    height: 60px;

    td {
      img {
        height: 160px
      }
    }
  }
}

.edit {
  text-align: left;
  font-size: 16px;
  background-color: #fff;
  border: 1px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  width: 1000px;
  height: 600px;

  input {
    width: 450px;
    height: 50px;
  }

  span {
    width: 120px;
    height: 50px;
    display: inline-block;
    text-align: center;
  }

}